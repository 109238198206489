import { type UserQueryQuery } from '@apps/www/src/__generated__/graphql';
import useUserAndBoardPageQuery from '@apps/www/src/www/hooks/useUserAndBoardPageQuery';
import { useRouter, type NextRouter } from 'next/router';
import React from 'react';
import wrapDisplayName from 'recompose/wrapDisplayName';
import SVGlobalLoadingWrapper from './SVGlobalLoadingWrapper';
import SVPageMeta from './SVPageMeta';

export type Props = {
	isOwner: boolean;
	user: UserQueryQuery['userByUsername'];
	board: ArrayElement<UserQueryQuery['userByUsername']['boards']> | null;
	router: NextRouter;
};

const SVWithUserPage = <P,>(WrappedComponent: React.ComponentType<P & Props>) => {
	const SVWithUserPage = (props: Omit<P, 'isOwner' | 'user' | 'board' | 'router'>) => {
		const router = useRouter();
		const { isOwner, user, board, loading, error } = useUserAndBoardPageQuery();

		if (error) {
			throw error;
		}

		return (
			<SVGlobalLoadingWrapper isLoading={loading}>
				{() => {
					if (!user) {
						return null;
					}

					const meta = {
						title: user.name,
						image: user.ogImage,
					};

					return (
						<>
							<SVPageMeta {...meta} />

							<WrappedComponent
								user={user}
								board={board}
								isOwner={isOwner}
								router={router}
								{...(props as P)}
							/>
						</>
					);
				}}
			</SVGlobalLoadingWrapper>
		);
	};

	SVWithUserPage.displayName = wrapDisplayName(WrappedComponent, 'SVWithUserPage');

	return SVWithUserPage;
};

export default SVWithUserPage;
